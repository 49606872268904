import React, { useEffect, useMemo } from "react";
import AnalyticsUtil from "utils/AnalyticsUtil";
import { useDynamicAppLayout } from "utils/hooks/useDynamicAppLayout";
import type { CanvasWidgetStructure } from "WidgetProvider/constants";
import { useSelector } from "react-redux";
import {
  getCurrentApplication,
  getAppSidebarPinned,
  getSidebarWidth,
  getAppMode,
  getIsShowBreadCrumb,
  getIfShowAutoMargin,
} from "ee/selectors/applicationSelectors";
import { NAVIGATION_SETTINGS } from "constants/AppConstants";
import { PageView, PageHeader, PageViewWrapper } from "./AppPage.styled";
import Breadcrumb from "./Breadcrumb";
import { SettingCategories } from "ee/pages/AdminSettings/config/types";
// import SettingsBreadcrumbs from "pages/Settings/SettingsBreadcrumbs"
import { useLocation } from "react-router-dom";
import { useIsMobileDevice } from "utils/hooks/useDeviceDetect";
import { APP_MODE } from "entities/App";
import { renderAppsmithCanvas } from "layoutSystems/CanvasFactory";
import type { WidgetProps } from "widgets/BaseWidget";
import { LayoutSystemTypes } from "layoutSystems/types";
import { getLayoutSystemType } from "selectors/layoutSystemSelectors";
import { useAppViewerSidebarProperties } from "utils/hooks/useAppViewerSidebarProperties";
import _get from "lodash/get";
interface AppPageProps {
  appName?: string;
  canvasWidth: number;
  pageId?: string;
  pageName?: string;
  widgetsStructure: CanvasWidgetStructure;
}

export function AppPage(props: AppPageProps) {
  const currentApplicationDetails = useSelector(getCurrentApplication);
  const isAppSidebarPinned = useSelector(getAppSidebarPinned);
  const sidebarWidth = useSelector(getSidebarWidth);
  const isMobileDevice = useIsMobileDevice();
  const appMode = useSelector(getAppMode);
  const isPublished = appMode === APP_MODE.PUBLISHED;
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const isEmbed = queryParams.get("embed");
  const isNavbarVisibleInEmbeddedApp = queryParams.get("navbar");
  const isEmbeddedAppWithNavVisible = isEmbed && isNavbarVisibleInEmbeddedApp;
  const layoutSystemType: LayoutSystemTypes = useSelector(getLayoutSystemType);
  const isAnvilLayout = layoutSystemType === LayoutSystemTypes.ANVIL;
  const { hasSidebarPinned } = useAppViewerSidebarProperties();
  const showBreadcrumb = useSelector(getIsShowBreadCrumb);
  const showAutoMargin = useSelector(getIfShowAutoMargin);
  const width: string = useMemo(() => {
    return isAnvilLayout ? "100%" : `${props.canvasWidth}px`;
  }, [isAnvilLayout, props.canvasWidth]);

  useDynamicAppLayout();

  useEffect(() => {
    AnalyticsUtil.logEvent("PAGE_LOAD", {
      pageName: props.pageName,
      pageId: props.pageId,
      appName: props.appName,
      mode: "VIEW",
    });
  }, [props.pageId, props.pageName]);
  const breadCrumbWidth = isAnvilLayout
    ? "100%"
    : `${props.canvasWidth - 20}px`;

  return (
    <PageViewWrapper
      hasPinnedSidebar={hasSidebarPinned}
      isPublished={isPublished}
      sidebarWidth={sidebarWidth}
      showBreadcrumb={showBreadcrumb}
      isMobileDevice={isMobileDevice}
    >
      {showBreadcrumb ? (
        <PageHeader width={breadCrumbWidth} hasSidebarPinned={hasSidebarPinned}>
          <Breadcrumb />
        </PageHeader>
      ) : null}

      <PageView
        className="t--app-viewer-page"
        width={width}
        showAutoMargin={showAutoMargin}
      >
        {props.widgetsStructure.widgetId &&
          renderAppsmithCanvas(props.widgetsStructure as WidgetProps)}
      </PageView>
    </PageViewWrapper>
  );
}

export default AppPage;

import type {
  ApplicationPayload,
  Page,
} from "ee/constants/ReduxActionConstants";
import { NAVIGATION_SETTINGS } from "constants/AppConstants";
import { get } from "lodash";
import { useHref } from "pages/Editor/utils";
import React from "react";
import { useSelector } from "react-redux";
import { builderURL } from "ee/RouteBuilder";
import { getSelectedAppTheme } from "selectors/appThemingSelectors";
import { getCurrentPage, getCurrentPageId } from "selectors/editorSelectors";
import MobileNavToggle from "./MobileNavToggle";
import ApplicationName from "./ApplicationName";
import ApplicationLogo from "./ApplicationLogo";
import ShareButton from "./ShareButton";
import HeaderRightItemContainer from "./HeaderRightItemContainer";
import PrimaryCTA from "pages/AppViewer/PrimaryCTA";
import type { User } from "constants/userConstants";
import { ANONYMOUS_USERNAME } from "constants/userConstants";
import ProfileDropdown from "pages/common/ProfileDropdown";
import TopStacked from "../TopStacked";
import { HeaderRow, StyledNav } from "./TopHeader.styled";
import TopInline from "../TopInline";
import { getAppsmithConfigs } from "ee/configs";
import BackToHomeButton from "ee/pages/AppViewer/BackToHomeButton";
import BackToAppsButton from "./BackToAppsButton";
import { getCurrentApplication } from "ee/selectors/applicationSelectors";
import { isHidden } from "components/formControls/utils";

interface TopHeaderProps {
  currentApplicationDetails?: ApplicationPayload;
  pages: Page[];
  currentWorkspaceId: string;
  currentUser?: User;
  isMenuOpen: boolean;
  setMenuOpen: (isMenuOpen: boolean) => void;
  showUserSettings: boolean;
  isMobile?: boolean;
}

const TopHeader = (props: TopHeaderProps) => {
  const {
    currentApplicationDetails,
    currentUser,
    currentWorkspaceId,
    isMenuOpen,
    isMobile,
    pages,
    setMenuOpen,
  } = props;
  const { inCloudOS } = getAppsmithConfigs();
  const hideAvatorDropdown = window.PAGEPLUG_KEYMANTECH_DEPLOY;
  const selectedTheme = useSelector(getSelectedAppTheme);
  const isMinimal =
    currentApplicationDetails?.applicationDetail?.navigationSetting
      ?.navStyle === NAVIGATION_SETTINGS.NAV_STYLE.MINIMAL;
  const logoConfiguration =
    currentApplicationDetails?.applicationDetail?.navigationSetting
      ?.logoConfiguration ||
    NAVIGATION_SETTINGS.LOGO_CONFIGURATION.LOGO_AND_APPLICATION_TITLE;
  const navColorStyle =
    get(
      currentApplicationDetails,
      "applicationDetail.navigationSetting.colorStyle",
      "",
    ) || NAVIGATION_SETTINGS.COLOR_STYLE.LIGHT;
  const navStyle =
    get(
      currentApplicationDetails,
      "applicationDetail.navigationSetting.navStyle",
      "",
    ) || NAVIGATION_SETTINGS.NAV_STYLE.STACKED;
  const navOrientation =
    get(
      currentApplicationDetails,
      "applicationDetail.navigationSetting.orientation",
      "",
    ) || NAVIGATION_SETTINGS.ORIENTATION.TOP;
  const primaryColor = get(
    selectedTheme,
    "properties.colors.primaryColor",
    "inherit",
  );
  const pageId = useSelector(getCurrentPageId);
  const editorURL = useHref(builderURL, { pageId });
  const currentApp = useSelector(getCurrentApplication);

  const viewerLayout = currentApp?.viewerLayout;
  let isHiddenPage: boolean = false;
  const findPageInTree = (treeData: any[], pageId: string): any | undefined => {
    for (const item of treeData) {
      if (item.isPage && item.pageId === pageId) {
        return item;
      }
      if (item.children && item.children.length > 0) {
        const result = findPageInTree(item.children, pageId);
        if (result) {
          return result;
        }
      }
    }
    return {};
  };

  if (viewerLayout) {
    const treeData = get(JSON.parse(viewerLayout), "treeData", []);
    const currentApp = findPageInTree(treeData, pageId);
    isHiddenPage = get(currentApp, "isHidden", false);
  }
  return (
    <StyledNav
      className="relative js-appviewer-header t--app-viewer-navigation-header z-8"
      data-testid={"t--app-viewer-navigation-header"}
      navColorStyle={navColorStyle}
      primaryColor={primaryColor}
    >
      <HeaderRow
        className="relative h-12 px-2 md:px-3"
        navColorStyle={navColorStyle}
        navStyle={navStyle}
        primaryColor={primaryColor}
      >
        <section className="flex items-center py-1">
          <MobileNavToggle
            isMenuOpen={isMenuOpen}
            navColorStyle={navColorStyle}
            primaryColor={primaryColor}
            setMenuOpen={setMenuOpen}
          />
          {/* {currentUser?.username !== ANONYMOUS_USERNAME && (
            <BackToHomeButton
              navColorStyle={navColorStyle}
              primaryColor={primaryColor}
            />
          )} */}
          <ApplicationLogo
            appName={currentApplicationDetails?.name}
            navColorStyle={navColorStyle}
            navOrientation={navOrientation}
            navStyle={navStyle}
            primaryColor={primaryColor}
            showAbbr={false}
          />
          {!isMinimal &&
            (logoConfiguration ===
              NAVIGATION_SETTINGS.LOGO_CONFIGURATION
                .LOGO_AND_APPLICATION_TITLE ||
              logoConfiguration ===
                NAVIGATION_SETTINGS.LOGO_CONFIGURATION
                  .APPLICATION_TITLE_ONLY) && (
              <ApplicationName
                appName={currentApplicationDetails?.name}
                navColorStyle={navColorStyle}
                navOrientation={NAVIGATION_SETTINGS.ORIENTATION.TOP}
                navStyle={navStyle}
                primaryColor={primaryColor}
              />
            )}
        </section>

        {currentApplicationDetails?.applicationDetail?.navigationSetting
          ?.orientation === NAVIGATION_SETTINGS.ORIENTATION.TOP &&
          currentApplicationDetails?.applicationDetail?.navigationSetting
            ?.navStyle === NAVIGATION_SETTINGS.NAV_STYLE.INLINE &&
          !isMobile &&
          !isHiddenPage && (
            <TopInline
              currentApplicationDetails={currentApplicationDetails}
              pages={pages}
            />
          )}

        {props.showUserSettings && !inCloudOS && (
          <section className="relative flex items-center space-x-3 z-1 ml-auto py-3">
            {currentUser && currentUser.username !== ANONYMOUS_USERNAME && (
              <HeaderRightItemContainer>
                <ProfileDropdown
                  modifiers={{
                    offset: {
                      enabled: true,
                      offset: `0, 0`,
                    },
                  }}
                  name={currentUser.name}
                  navColorStyle={navColorStyle}
                  noAction={hideAvatorDropdown}
                  photoId={currentUser?.photoId}
                  primaryColor={primaryColor}
                  userName={currentUser?.username || ""}
                />
              </HeaderRightItemContainer>
            )}
          </section>
        )}
      </HeaderRow>

      {currentApplicationDetails?.applicationDetail?.navigationSetting
        ?.orientation === NAVIGATION_SETTINGS.ORIENTATION.TOP &&
        currentApplicationDetails?.applicationDetail?.navigationSetting
          ?.navStyle === NAVIGATION_SETTINGS.NAV_STYLE.STACKED &&
        !isMobile &&
        !isHiddenPage && (
          <TopStacked
            currentApplicationDetails={currentApplicationDetails}
            pages={pages}
          />
        )}
    </StyledNav>
  );
};

export default TopHeader;

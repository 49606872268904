import { Button, Popover, QRCode, Space, Spin } from "antd";
import type { QRCodeProps } from "antd";
import React from "react";
import { QRStatus } from "antd/es/qr-code/interface";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ReloadOutlined,
} from "@ant-design/icons";
import { isEmpty } from "lodash";

const AntdQrCodeComponent = (props: AntdQrCodeComponentProps) => {
  const {
    value,
    icon,
    errorLevel,
    size,
    color,
    bgColor,
    borderRadius,
    boxShadow,
    bordered,
    status,
    onRefresh,
    tooltip,
    codeType = "svg",
  } = props;

  const tooltipContent = () => {
    return !isEmpty(tooltip) ? (
      <div className="text-[#000]">{tooltip}</div>
    ) : (
      ""
    );
  };

  const customStatusRender: QRCodeProps["statusRender"] = (info) => {
    switch (info.status) {
      case "expired":
        return (
          <div>
            <CloseCircleFilled style={{ color: "red" }} />{" "}
            {info.locale?.expired}
            <p>
              <Button type="link" onClick={info.onRefresh}>
                <ReloadOutlined /> {info.locale?.refresh}
              </Button>
            </p>
          </div>
        );
      case "loading":
        return (
          <Space direction="vertical">
            <Spin />
            <p>Loading...</p>
          </Space>
        );
      case "scanned":
        return (
          <div>
            <CheckCircleFilled style={{ color: "green" }} />{" "}
            {info.locale?.scanned}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Popover content={tooltipContent()}>
        <QRCode
          style={{
            width: "100%",
            height: "100%",
            borderRadius: borderRadius,
            boxShadow: boxShadow,
          }}
          value={value}
          icon={icon}
          type={codeType}
        //   size={size}
          status={status}
          color={color}
          bgColor={bgColor}
          errorLevel={errorLevel}
          bordered={bordered}
          onRefresh={onRefresh}
          statusRender={customStatusRender}
        />
      </Popover>
    </>
  );
};

export interface AntdQrCodeComponentProps {
  value: string;
  icon?: string;
  size?: number;
  color?: string;
  bgColor?: string;
  bordered?: boolean;
  errorLevel?: QRCodeProps["errorLevel"];
  borderRadius?: string;
  boxShadow?: string;
  status?: QRStatus;
  onRefresh?: () => void;
  tooltip?: string;
  codeType: QRCodeProps["type"];
}

export default AntdQrCodeComponent;

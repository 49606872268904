import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Flex, SearchInput } from "design-system";
import { useSelector } from "react-redux";

import { getCurrentPageId } from "ee/selectors/entitiesSelector";
import styled from "styled-components";
import { Colors } from "constants/Colors";
import { useWidgetDragResize } from "utils/hooks/dragResizeHooks";
import { generateReactKey } from "utils/generators";
import { useWidgetSelection } from "utils/hooks/useWidgetSelection";
import { debounce, omit } from "lodash";
import { getModulesData } from "ce/selectors/applicationSelectors";
import { useIsEditorPaneSegmentsEnabled } from "../../hooks";
import WalkthroughContext from "components/featureWalkthrough/walkthroughContext";
import { getCurrentApplication } from "selectors/applicationSelectors";
import { ApplicationPayload } from "ee/constants/ReduxActionConstants";
import ModuleSvg from "./ModuleSvg";
import moment from "moment";

export const Wrapper = styled.div<{ isThumbnail?: boolean }>`
  border-radius: var(--ads-v2-border-radius);
  border: none;
  position: relative;
  color: ${Colors.MINT_BLACK};
  display: flex;
  justify-content: start;
  cursor: grab;
  user-select: none;
  -webkit-user-select: none;
  flex-direction: column;
  margin-bottom: 2px;
  padding: 8px 13px;
  img {
    cursor: grab;
  }

  &:hover {
    background: #f0f0f0;
    cursor: grab;
  }

  & i {
    font-family: ${(props) => props.theme.fonts.text};
    font-size: ${(props) => props.theme.fontSizes[7]}px;
  }

  ${(props) =>
    props.isThumbnail &&
    `margin-bottom: 0px;

    & span {
      padding-left: var(--ads-v2-spaces-3);
      padding-right: var(--ads-v2-spaces-3);
      color: var(--ads-v2-color-fg);
      font-weight: 600;
      line-height: 1.2;
      padding-bottom: var(--ads-v2-spaces-3);
    }


    `}
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    cursor: grab;
    .module-icon {
      box-shadow: 0 0 5px 0 rgba(49, 94, 251, 0.15);
      border-color: #b3c4ff;
      transform: scale(1.2);
    }
    .module-name {
      color: #315efb;
    }
  }
  .module-icon {
    transition: all 200ms linear;
    margin-right: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d7d9e0;
    border-radius: 4px;
  }
  .module-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
  }
  .module-name {
    line-height: 1.5;
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .module-desc {
    line-height: 1.5;
    font-size: 12px;
    color: #8b8fa3;
  }
`;

interface ModuleLayout {
  createdAt: string;
  id: string;
  name: string;
  pageDTO: any;
  type: string;
  workspaceId: string;
  workspaceName: string;
}

// @module
const AddModuls = () => {
  const { isOpened: isWalkthroughOpened, popFeature } =
    useContext(WalkthroughContext) || {};
  const isMobile = useSelector((state) => state.ui.mainCanvas.isMobile);
  const pageId = useSelector(getCurrentPageId) as string;
  const { setDraggingNewWidget } = useWidgetDragResize();
  const { deselectAll } = useWidgetSelection();
  const isEditorPaneEnabled = useIsEditorPaneSegmentsEnabled();
  const closeWalkthrough = useCallback(() => {
    if (isWalkthroughOpened && popFeature) {
      popFeature();
    }
  }, [isWalkthroughOpened, popFeature]);

  const modulesData = useSelector(getModulesData);
  const currentApplication: ApplicationPayload = useSelector(
    getCurrentApplication,
  )!;
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [filteredModules, setFilteredModules] = useState<any>(modulesData);
  const [filterKeywords, setFilterKeywords] = useState<string>("");
  const updateWidgetIds = (
    widget: any,
    parentId: string | undefined,
    moduleId: string,
    isFirstContainer = true,
  ) => {
    const newWidgetId = generateReactKey();

    let updatedWidget = {
      ...widget,
      widgetId: newWidgetId,
      parentId: parentId !== undefined ? parentId : undefined,
      isModule: true,
      moduleId: moduleId,
    };

    if (isFirstContainer && widget.type === "CONTAINER_WIDGET") {
      updatedWidget.parentId = "0";
      isFirstContainer = false;
    }

    if (
      Array.isArray(updatedWidget.children) &&
      updatedWidget.children.length > 0
    ) {
      updatedWidget.children = updatedWidget.children.map((child) =>
        updateWidgetIds(child, newWidgetId, moduleId, isFirstContainer),
      );
    }

    return updatedWidget;
  };

  const transformData = (data: ModuleLayout) => {
    return {
      ...data,
      pageDTO: {
        ...data.pageDTO,
        layouts: data.pageDTO.layouts.map((layout) => {
          return {
            ...layout,
            dsl: updateWidgetIds(layout.dsl, undefined, data.id),
          };
        }),
      },
    };
  };

  const onDragStart = (e: any, data: any) => {
    e.preventDefault();
    e.stopPropagation();
    const dragStartData = transformData(data);
    setDraggingNewWidget(
      true,
      omit(
        {
          ...dragStartData.pageDTO.layouts[0].dsl.children[0],
          rows: 20,
          columns: 50,
        },
        ["renderMode", "widgetName"],
      ),
    );
    deselectAll();
    if (!isEditorPaneEnabled) {
      closeWalkthrough();
    }
  };

  const filterModules = (keyword: string) => {
    setFilterKeywords(keyword);
    if (keyword.trim().length > 0) {
      const filterModules = modulesData.filter((item: { name: string }) =>
        item.name.includes(keyword),
      );
      setFilteredModules(filterModules);
    } else {
      setFilteredModules(modulesData);
      setFilterKeywords("");
    }
  };

  const search = debounce((value: string) => {
    filterModules(value.toLowerCase());
  }, 300);

  useEffect(() => {
    setFilteredModules(modulesData);
  }, [modulesData]);
  return (
    <>
      <div className="sticky top-0 px-3 mt-3">
        <SearchInput
          aria-label={"搜索"}
          autoComplete="off"
          id={"module-search"}
          onChange={search}
          placeholder="搜索"
          ref={searchInputRef}
          type="text"
        />
      </div>
      <Flex flexDirection="column" gap="spaces-3" overflowX="scroll">
        {filteredModules.length > 0 &&
          filteredModules.map(
            (item: { id: string; name: string; createdAt: string }) => {
              const className = `t--widget-card-draggable t--widget-card-draggable-${item.id}`;
              return (
                <Wrapper
                  className={className}
                  data-guided-tour-id={`widget-card-${item.id}`}
                  draggable
                  id={`widget-card-draggable-${item.id}`}
                  key={`widget-card-draggable-${item.id}`}
                  onDragStart={(e) => onDragStart(e, item)}
                >
                  <ItemWrapper>
                    <div className="module-icon">
                      <ModuleSvg />
                    </div>
                    <div className="module-content">
                      <div className="module-name truncate max-w-[200px]">
                        {item.name}
                      </div>
                      <div className="module-desc">
                        {moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                      </div>
                    </div>
                  </ItemWrapper>
                </Wrapper>
              );
            },
          )}
        {filteredModules.length === 0 && (
          <div className="flex-grow px-3 mt-2 overflow-y-scroll">
            找不到模块: {filterKeywords}
          </div>
        )}
        {/* {mockModulesData.map((item) => {
          const className = `t--widget-card-draggable t--widget-card-draggable-${item.id}`;
          return (
            <Wrapper
              className={className}
              data-guided-tour-id={`widget-card-${item.id}`}
              draggable
              id={`widget-card-draggable-${item.id}`}
              onDragStart={(e) => onDragStart(e, item)}
            >
              <Text kind="body-s">{item.name}</Text>
            </Wrapper>
          );
        })} */}
      </Flex>
    </>
  );
};

export default AddModuls;

import React, { Suspense } from "react";
import { Alignment } from "@blueprintjs/core";
import { isArray, isEmpty } from "lodash";

import { AutocompleteDataType } from "utils/autocomplete/AutocompleteDataType";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import { isAutoLayout } from "layoutSystems/autolayout/utils/flexWidgetUtils";
import { WIDGET_TAGS, type TextSize } from "constants/WidgetConstants";
import { ValidationTypes } from "constants/WidgetValidation";
import type { ValidationResponse } from "constants/WidgetValidation";
import { LabelPosition } from "components/constants";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { AutocompletionDefinitions } from "WidgetProvider/constants";
import { DefaultAutocompleteDefinitions } from "widgets/WidgetUtils";
import type { SetterConfig, Stylesheet } from "entities/AppTheming";
import { EvaluationSubstitutionType } from "entities/DataTree/dataTreeFactory";

import TreeComponent from "../component";
import derivedProperties from "./parseDerivedProperties";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import IconSVG from "../icon.svg";
import { DynamicHeight } from "utils/WidgetFeatures";
import { Skeleton } from "antd";
import { retryPromise } from "utils/AppsmithUtils";

type Key = string | number;

// 默认选项校验
function defaultOptionValueValidation(value: unknown): ValidationResponse {
  if (typeof value === "string") return { isValid: true, parsed: value.trim() };
  if (value === undefined || value === null)
    return {
      isValid: false,
      parsed: "",
      messages: [
        {
          name: "TypeError",
          message: "This value does not evaluate to type: string",
        },
      ],
    };
  return { isValid: true, parsed: value };
}

const LazyTreeComponent = React.lazy(async () =>
  retryPromise(async () => import("../component")),
);
class TreeWidget extends BaseWidget<TreeWidgetProps, WidgetState> {
  static type = "TREE_WIDGET";
  // 常规属性配置
  static getConfig() {
    return {
      name: "树形",
      iconSVG: IconSVG,
      needsMeta: true,
      isCanvas: false,
      tags: [WIDGET_TAGS.SELECT],
      searchTags: ["tree", "树"],
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 2,
        defaultValue: DynamicHeight.FIXED,
        active: true,
      },
      // [RegisteredWidgetFeatures.FLOAT_LAYOUT]: {} as any,
    };
  }

  static getDefaults() {
    return {
      widgetName: "Tree",
      rows: 12,
      columns: 13,
      version: 1,
      options: [
        {
          label: "川菜",
          value: "1",
          children: [
            {
              label: "麻辣烫",
              value: "1-1",
              children: [
                {
                  label: "牛肉麻辣烫",
                  value: "1-1-1",
                },
                {
                  label: "鸭血麻辣烫",
                  value: "1-1-2",
                },
              ],
            },
            {
              label: "火锅",
              value: "1-2",
              children: [
                {
                  label: "麻辣火锅",
                  value: "1-2-1",
                },
                {
                  label: "清汤火锅",
                  value: "1-2-2",
                },
              ],
            },
          ],
        },
        {
          label: "粤菜",
          value: "2",
          children: [
            {
              label: "烧腊",
              value: "2-1",
              children: [
                {
                  label: "烧鸭",
                  value: "2-1-1",
                },
                {
                  label: "烧肉",
                  value: "2-1-2",
                },
              ],
            },
            {
              label: "蒸菜",
              value: "2-2",
              children: [
                {
                  label: "蒸鱼",
                  value: "2-2-1",
                },
                {
                  label: "蒸虾饺",
                  value: "2-2-2",
                },
              ],
            },
          ],
        },
      ],
      defaultOptionValue: ["2-2-2", "2-2-1"],
      isVisible: true,
      // isRequired: false,
      isDisabled: false,
      animateLoading: true,
      // allowClear: false,
      expandAll: false,
      // placeholderText: "请选择",
      labelText: "标签",
      labelPosition: LabelPosition.Top,
      labelAlignment: Alignment.LEFT,
      labelWidth: 5,
      labelTextSize: "0.875rem",
      // responsiveBehavior: ResponsiveBehavior.Fill,
      // minWidth: FILL_WIDGET_MIN_WIDTH,
    };
  }

  static getAutoLayoutConfig() {
    return {
      autoDimension: {
        height: true,
      },
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "120px",
              minHeight: "40px",
            };
          },
        },
      ],
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "数据",
        children: [
          {
            helpText: "允许用户多选，每个选项的值必须唯一",
            propertyName: "options",
            label: "选项",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入选项数据",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: false,
            validation: {
              type: ValidationTypes.NESTED_OBJECT_ARRAY,
              params: {
                unique: ["value"],
                default: [],
                children: {
                  type: ValidationTypes.OBJECT,
                  params: {
                    allowedKeys: [
                      {
                        name: "label",
                        type: ValidationTypes.TEXT,
                        params: {
                          default: "",
                          required: true,
                        },
                      },
                      {
                        name: "value",
                        type: ValidationTypes.TEXT,
                        params: {
                          default: "",
                        },
                      },
                      {
                        name: "children",
                        type: ValidationTypes.ARRAY,
                        required: false,
                        params: {
                          children: {
                            type: ValidationTypes.OBJECT,
                            params: {
                              allowedKeys: [
                                {
                                  name: "label",
                                  type: ValidationTypes.TEXT,
                                  params: {
                                    default: "",
                                    required: true,
                                  },
                                },
                                {
                                  name: "value",
                                  type: ValidationTypes.TEXT,
                                  params: {
                                    default: "",
                                  },
                                },
                              ],
                            },
                          },
                        },
                      },
                    ],
                  },
                },
              },
            },
            evaluationSubstitutionType:
              EvaluationSubstitutionType.SMART_SUBSTITUTE,
          },
          {
            helpText: "默认选中这个值",
            propertyName: "defaultOptionValue",
            label: "默认选中值",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入选项数据",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.FUNCTION,
              params: {
                fn: defaultOptionValueValidation,
                expected: {
                  type: "key",
                  example: `value1`,
                  autocompleteDataType: AutocompleteDataType.STRING,
                },
              },
            },
          },
        ],
      },
      {
        sectionName: "标签",
        children: [
          {
            helpText: "设置组件标签文本",
            propertyName: "labelText",
            label: "文本",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入文本内容",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "设置组件标签位置",
            propertyName: "labelPosition",
            label: "位置",
            controlType: "ICON_TABS",
            fullWidth: true,
            hidden: isAutoLayout,
            options: [
              { label: "自动", value: LabelPosition.Auto },
              { label: "左", value: LabelPosition.Left },
              { label: "上", value: LabelPosition.Top },
            ],
            defaultValue: LabelPosition.Top,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "设置组件标签的对齐方式",
            propertyName: "labelAlignment",
            label: "对齐",
            controlType: "LABEL_ALIGNMENT_OPTIONS",
            options: [
              {
                startIcon: "align-left",
                value: Alignment.LEFT,
              },
              {
                startIcon: "align-right",
                value: Alignment.RIGHT,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
            hidden: (props: TreeWidgetProps) =>
              props.labelPosition !== LabelPosition.Left,
            dependencies: ["labelPosition"],
          },
          {
            helpText: "设置组件标签占用的列数",
            propertyName: "labelWidth",
            label: "宽度（所占列数）",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
            hidden: (props: TreeWidgetProps) =>
              props.labelPosition !== LabelPosition.Left,
            dependencies: ["labelPosition"],
          },
        ],
      },
      {
        sectionName: "属性",
        children: [
          {
            helpText: "目录图标",
            propertyName: "showDirectoryIcon",
            label: "显示目录图标",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            helpText: "是否可勾选",
            propertyName: "checkable",
            label: "是否可勾选",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            helpText: "展示连接线",
            propertyName: "showLine",
            label: "是否展示连接线",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            helpText: "节点占据一行",
            propertyName: "blockNode",
            label: "是否占据一行",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            helpText: "控制组件的显示/隐藏",
            propertyName: "isVisible",
            label: "是否显示",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "isDisabled",
            label: "禁用",
            helpText: "让组件不可交互",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "expandAll",
            label: "默认展开",
            helpText: "默认展开所有层级的选项",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
      {
        sectionName: "事件",
        children: [
          {
            helpText: "用户展开/折叠时触发，带回调参数",
            propertyName: "onExpand",
            label: "(cb) => onExpand",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
          {
            helpText: "点击树节点时触发, 带回调参数",
            propertyName: "onSelect",
            label: "(cb) => onSelect",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }
  static getStylesheetConfig(): Stylesheet {
    return {
      accentColor: "{{appsmith.theme.colors.primaryColor}}",
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
    };
  }
  // 样式配置
  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "标签样式",
        children: [
          {
            propertyName: "labelTextColor",
            label: "字体颜色",
            helpText: "设置标签字体颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "labelTextSize",
            label: "字体大小",
            helpText: "设置标签字体大小",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            hidden: isAutoLayout,
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "labelStyle",
            label: "强调",
            helpText: "设置标签字体是否加粗或斜体",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "轮廓样式",
        children: [
          {
            propertyName: "accentColor",
            label: "强调色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
            invisible: true,
          },
          {
            propertyName: "borderRadius",
            label: "边框圆角",
            helpText: "边框圆角样式",
            controlType: "BORDER_RADIUS_OPTIONS",

            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText: "组件轮廓投影",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }
  // 智能提示
  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      selectedOptionValues: "[string]",
      selectedOptionLabels: "[string]",
      isDisabled: "boolean",
      options: "[TreeOption]",
    };
  }
  // 派生属性，可智能提示
  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      flattenedOptions: `{{(()=>{${derivedProperties.getFlattenedOptions}})()}}`,
      selectedOptionValues: `{{(()=>{${derivedProperties.getSelectedOptionValues}})()}}`,
      selectedOptionLabels: `{{(()=>{${derivedProperties.getSelectedOptionLabels}})()}}`,
    };
  }
  // 默认值
  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      selectedOptionValueArr: "defaultOptionValue",
      selectedLabel: "defaultOptionValue",
    };
  }
  // 元数据
  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedOptionValueArr: undefined,
      selectedLabel: undefined,
      isDirty: false,
    };
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
        setOptions: {
          path: "options",
          type: "object",
        },
      },
    };
  }

  componentDidUpdate(prevProps: TreeWidgetProps): void {
    if (
      this.props.defaultOptionValue !== prevProps.defaultOptionValue &&
      this.props.isDirty
    ) {
      this.props.updateWidgetMetaProperty("isDirty", false);
    }
  }

  calculateWidgetBounds(
    rightColumn: number,
    leftColumn: number,
    topRow: number,
    bottomRow: number,
    parentColumnSpace: number,
    parentRowSpace: number,
  ): {
    componentWidth: number;
    componentHeight: number;
  } {
    return {
      componentWidth: (rightColumn - leftColumn) * parentColumnSpace,
      componentHeight: (bottomRow - topRow) * parentRowSpace,
    };
  }

  getComponentDimensions = () => {
    return this.calculateWidgetBounds(
      this.props.rightColumn,
      this.props.leftColumn,
      this.props.topRow,
      this.props.bottomRow,
      this.props.parentColumnSpace,
      this.props.parentRowSpace,
    );
  };

  // 视图
  getWidgetView() {
    const options = isArray(this.props.options) ? this.props.options : [];
    const {
      accentColor,
      borderRadius,
      boxShadow,
      blockNode,
      checkable,
      compactMode,
      defaultOptionValue,
      dynamicHeight,
      expandAll,
      isDisabled,
      // animateLoading,
      showDirectoryIcon,
      labelAlignment,
      labelComponentWidth,
      labelPosition,
      labelStyle,
      labelText,
      labelTextColor,
      labelTextSize,
      showColon,
      showLine,
    } = this.props;
    const { componentHeight } = this.getComponentDimensions();

    return (
      <Suspense fallback={<Skeleton />}>
        <LazyTreeComponent
          accentColor={accentColor}
          borderRadius={borderRadius}
          boxShadow={boxShadow}
          blockNode={blockNode}
          checkable={checkable}
          compactMode={compactMode}
          componentHeight={componentHeight}
          defaultCheckedKeys={defaultOptionValue}
          dynamicHeight={dynamicHeight}
          expandAll={expandAll}
          isDisabled={isDisabled}
          isRequired={this.props.isRequired}
          showDirectoryIcon={showDirectoryIcon}
          labelAlignment={labelAlignment}
          labelPosition={labelPosition}
          labelStyle={labelStyle}
          labelText={labelText}
          labelTextColor={labelTextColor}
          labelTextSize={labelTextSize}
          labelWidth={labelComponentWidth}
          onCheck={this.onCheck}
          onExpandHandle={this.onExpand}
          onSelectHandle={this.onSelect}
          options={options}
          // animateLoading={animateLoading}
          showColon={showColon}
          showLine={showLine}
        />
      </Suspense>
    );
  }
  // 更新选中节点
  onCheck = (checked: Key[] | { checked: Key[]; halfChecked: Key[] }) => {
    if (!this.props.isDirty) {
      this.props.updateWidgetMetaProperty("isDirty", true);
    }
    this.props.updateWidgetMetaProperty("selectedOptionValueArr", checked);
    this.props.updateWidgetMetaProperty("selectedLabel", checked);

    // 这个方式会改到默认值
    // super.updateWidgetProperty("defaultOptionValue", checked);
  };
  // 事件
  onExpand = (expandedKeys: any, info?: any) => {
    if (this.props.onExpand) {
      const cbData = [{ expandedKeys }];
      super.executeAction({
        triggerPropertyName: "onExpand",
        dynamicString: this.props.onExpand,
        event: {
          type: EventType.ON_EXPAND,
        },
        callbackData: cbData,
      });
    }
  };

  onSelect = (selectedKeys: any, info?: any) => {
    if(!isEmpty(selectedKeys)){
      this.props.updateWidgetMetaProperty("selectedOptionValueArr", selectedKeys);
      this.props.updateWidgetMetaProperty("selectedLabel", selectedKeys);
    }
    if (this.props.onSelect) {
      const cbData = [{ selectedKeys }];
      super.executeAction({
        triggerPropertyName: "onSelect",
        dynamicString: this.props.onSelect,
        event: {
          type: EventType.ON_SELECT,
        },
        callbackData: cbData,
      });
    }
  };
}

export interface TreeOption {
  title: string;
  key: string | number;
  disabled?: boolean;
  disableCheckbox?: boolean;
  children?: TreeOption[];
}

export interface TreeWidgetProps extends WidgetProps {
  options?: TreeOption[];
  defaultOptionValue: string[];
  labelText: string;
  labelPosition?: LabelPosition;
  labelAlignment?: Alignment;
  labelWidth?: number;
  isDisabled: boolean;
  // animateLoading: boolean;
  expandAll: boolean;
  labelTextColor?: string;
  labelTextSize?: TextSize;
  labelStyle?: string;
  borderRadius: string;
  boxShadow?: string;
  selectedLabel: string[];
  selectedOptionValueArr: string[];
  showColon?: boolean;
  onSelect?: string;
  onExpand?: string;
}

export default TreeWidget;

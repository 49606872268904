// This file contains common constants which can be used across the widget configuration file (index.ts), widget and
export const ANTDQRCODE_WIDGET_CONSTANT = "";

export enum Level {
  low = "L",
  middle = "M",
  higher = "Q",
  high = "H",
}


export enum CodeStatus {
  active = "active",
  expired = "expired",
  loading = "loading",
  scanned = "scanned",
}

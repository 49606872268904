import React, { useEffect, useState, useMemo } from "react";
import type {
  ApplicationPayload,
  Page,
} from "ee/constants/ReduxActionConstants";
import { NAVIGATION_SETTINGS, SIDEBAR_WIDTH } from "constants/AppConstants";
import {
  get,
  get as _get,
  head as _head,
  size as _size,
  map as _map,
  last as _last,
} from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getSelectedAppTheme } from "selectors/appThemingSelectors";
import ApplicationName from "./components/ApplicationName";
import { useHref } from "pages/Editor/utils";
import { builderURL, viewerURL } from "ee/RouteBuilder";
import {
  getCurrentPageId,
  previewModeSelector,
  getCurrentPage,
} from "selectors/editorSelectors";
import type { User } from "constants/userConstants";
import SidebarProfileComponent from "./components/SidebarProfileComponent";
import CollapseButton from "./components/CollapseButton";
import classNames from "classnames";
import { useMouse } from "@mantine/hooks";
import {
  getAppSidebarPinned,
  getCurrentApplication,
  getAppMode,
} from "ee/selectors/applicationSelectors";
import { setIsAppSidebarPinned } from "ee/actions/applicationActions";
import {
  StyledStackFooter,
  StyledStackedMenuContainer,
  StyledStackedSidebar,
} from "./Sidebar.styled";
import { View } from "@tarojs/components";
import { getCurrentThemeDetails } from "selectors/themeSelectors";
import { getIsAppSettingsPaneWithNavigationTabOpen } from "selectors/appSettingsPaneSelectors";
import { Icon } from "design-system";
import ApplicationLogo from "./components/ApplicationLogo";

import history from "utils/history";
import { APP_MODE } from "entities/App";
import { Menu, ConfigProvider } from "antd";
import {
  filterHiddenTreeData,
  mapClearTree,
  processTreeData as configKeyForTree,
} from "utils/treeUtils";
import { getPath, findPathNodes } from "../utils";
import styled from "styled-components";
import { renderIcon } from "components/common/AntdIcon";
// import NavigationLogo from "ee/pages/AppViewer/NavigationLogo";

interface SidebarProps {
  currentApplicationDetails?: ApplicationPayload;
  pages: Page[];
  currentWorkspaceId: string;
  currentUser: User | undefined;
  showUserSettings: boolean;
}

const MyMenu = styled(Menu)<{
  theme: string;
  primaryColor: string;
  navColorStyle: any;
}>`
  color: "rgba(0,0,0,0.65)";
  .ant-menu-submenu {
    font-weight: 500;
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    border-radius: 6px !important;
  }
`;

const TheCollapsedIcon = styled.div<{
  isOpen: boolean;
}>`
  width: 20px;
  position: fixed;
  left: ${({ isOpen }) =>
    isOpen ? SIDEBAR_WIDTH.REGULAR - 28 : SIDEBAR_WIDTH.MINIMAL + 8}px;
  bottom: 10px;
`;

function getParentId(list: any, key: any) {
  for (const i in list) {
    if (list[i].key == key) {
      return [list[i]];
    }
    if (list[i].children) {
      const node: any = getParentId(list[i].children, key);
      if (node !== undefined) {
        return node.concat(list[i]);
      }
    }
  }
}

export function SidebarStacked(props: SidebarProps) {
  const selectedTheme = useSelector(getSelectedAppTheme);
  const appMode = useSelector(getAppMode);
  const { currentApplicationDetails, currentUser, currentWorkspaceId, pages } =
    props;
  const navColorStyle =
    currentApplicationDetails?.applicationDetail?.navigationSetting
      ?.colorStyle || NAVIGATION_SETTINGS.COLOR_STYLE.LIGHT;
  const navStyle =
    currentApplicationDetails?.applicationDetail?.navigationSetting?.navStyle ||
    NAVIGATION_SETTINGS.NAV_STYLE.STACKED;
  const isMinimal =
    currentApplicationDetails?.applicationDetail?.navigationSetting
      ?.navStyle === NAVIGATION_SETTINGS.NAV_STYLE.MINIMAL;
  const logoConfiguration =
    currentApplicationDetails?.applicationDetail?.navigationSetting
      ?.logoConfiguration ||
    NAVIGATION_SETTINGS.LOGO_CONFIGURATION.LOGO_AND_APPLICATION_TITLE;
  const primaryColor = get(
    selectedTheme,
    "properties.colors.primaryColor",
    "inherit",
  );
  const borderRadius = get(
    selectedTheme,
    "properties.borderRadius.appBorderRadius",
    "inherit",
  );
  const location = useLocation();
  const { pathname } = location;
  const [query, setQuery] = useState("");
  const pageId = useSelector(getCurrentPageId);
  const editorURL = useHref(builderURL, { pageId });
  const dispatch = useDispatch();
  const isPinned = useSelector(getAppSidebarPinned);
  const [isOpen, setIsOpen] = useState(true);
  const { x } = useMouse();
  const theme = useSelector(getCurrentThemeDetails);
  const isPreviewMode = useSelector(previewModeSelector);
  const isAppSettingsPaneWithNavigationTabOpen = useSelector(
    getIsAppSettingsPaneWithNavigationTabOpen,
  );
  // const [isLogoVisible, setIsLogoVisible] = useState(false);
  const [activeFirstMenu, setActiveFirstMenu] = useState<any>();

  const currentApp = useSelector(getCurrentApplication);
  const currentPage = useSelector(getCurrentPage);

  const viewerLayout = currentApp?.viewerLayout;
  const gotToPath = (pId: string, path: string) => {
    history.push(path);
  };

  const pagesMap = pages.reduce((a: any, c: any) => {
    a[c.pageId] = { ...c };
    return a;
  }, {});

  const initState = useMemo(() => {
    let menudata: any = [];
    let parentPaths: any = [];
    if (viewerLayout && pages.length) {
      try {
        const current = JSON.parse(viewerLayout);
        const _outsiderTree = current.outsiderTree || [];
        menudata = current?.treeData
          .map((itdata: any, idx: number) => {
            return mapClearTree(itdata, (item: any) => {
              const path = getPath(item, pagesMap, item.pageId, appMode);
              const title = _get(
                pagesMap,
                [item.pageId, "pageName"],
                item.title,
              ); //用最新的 pageName
              if (_outsiderTree.find((n: any) => n.pageId === item.pageId)) {
                return false;
              }
              if (item.title && item.isPage && !pagesMap[item.pageId]) {
                return false;
              }
              return {
                ...item,
                key: item.pageId || item.title + idx,
                children: _size(item.children) ? item.children : null,
                label: item.pageId ? (
                  <a
                    key={item.pageId}
                    onClick={() => gotToPath(item.pageId, path)}
                  >
                    {title}
                  </a>
                ) : (
                  title
                ),
                icon: (() => {
                  if (!item.icon) {
                    return null;
                  }
                  return item.icon.iconName !== "(none)" &&
                    item.icon.iconName !== "NONE" ? (
                    <div style={{ verticalAlign: "-0.3em" }}>
                      {renderIcon(item.icon)}
                    </div>
                  ) : null;
                })(),
              };
            });
          })
          .filter((i: any) => i);
      } catch (e) {
        console.log(e);
      }
    } else {
      menudata = pages.map((p) => {
        const path = getPath(p, pagesMap, p.pageId, appMode);
        return {
          title: p.pageName,
          pageId: p.pageId,
          isPage: true,
          key: p.pageId,
          label: p.pageId ? (
            <a key={p.pageId} onClick={() => gotToPath(p.pageId, path)}>
              {p.pageName}
            </a>
          ) : (
            p.pageName
          ),
          children: null,
        };
      });
    }
    if (!activeFirstMenu) {
      const currentPageId: any = currentPage?.pageId;
      parentPaths = findPathNodes(menudata, currentPageId);
      setActiveFirstMenu(_head(parentPaths));
    }
    // menudata = configKeyForTree(menudata);
    return {
      menudata,
      parentPaths,
    };
  }, [viewerLayout, pages, currentApplicationDetails]);

  const [openkeys, setOpenKeys] = useState(initState.parentPaths);

  useEffect(() => {
    setOpenKeys(initState.parentPaths);
  }, [initState.parentPaths]);

  useEffect(() => {
    setQuery(window.location.search);
  }, [location]);

  // Mark default page as first page
  const appPages = pages;
  if (appPages.length > 1) {
    appPages.forEach((item, i) => {
      if (item.isDefault) {
        appPages.splice(i, 1);
        appPages.unshift(item);
      }
    });
  }

  useEffect(() => {
    setIsOpen(isPinned);
  }, [isPinned]);

  // useEffect(() => {
  //   // When the sidebar is unpinned -
  //   if (!isPinned) {
  //     if (x <= 20) {
  //       // 1. Open the sidebar when hovering on the left edge of the screen
  //       setIsOpen(true);
  //     } else if (x > SIDEBAR_WIDTH.REGULAR) {
  //       // 2. Close the sidebar when the mouse moves out of it
  //       setIsOpen(false);
  //     }
  //   }
  // }, [x]);

  const setIsPinned = (isPinned: boolean) => {
    dispatch(setIsAppSidebarPinned(isPinned));
  };

  const calculateSidebarHeight = () => {
    let prefix = `calc( 100vh - `;
    const suffix = ")";
    if (isPreviewMode) {
      prefix += `${theme.smallHeaderHeight} - ${theme.bottomBarHeight}`;
    } else if (isAppSettingsPaneWithNavigationTabOpen) {
      prefix += `${theme.smallHeaderHeight} - ${theme.bottomBarHeight} - 66px`;
    } else {
      prefix += "0px";
    }

    return prefix + suffix;
  };

  const current_theme =
    get(
      currentApplicationDetails,
      ["applicationDetail", "navigationSetting", "colorStyle"],
      "theme",
    ) === "theme"
      ? "dark"
      : "light";

  const filteredItems = filterHiddenTreeData(initState.menudata);
  const onClickFirstMenu = (item: any) => {
    setActiveFirstMenu(item.key);
    if (item.isPage) {
      const path = getPath(item, pagesMap, item.pageId, appMode);
      gotToPath(item.key, path);
      setIsPinned(false);
    } else {
      setIsPinned(true);
    }
  };

  const restMenuData = get(
    filteredItems.find((m: any) => m.key === activeFirstMenu),
    "children",
    [],
  );

  const pickMenu = (item: any) => {
    const result: any[] = _map(
      getParentId(filteredItems, item.key),
      (it: any) => it.key,
    );
    const res = _last(result);
    setActiveFirstMenu(res);
  };
  const isSubMenuOpen = !!_size(restMenuData) && isOpen;

  return (
    <StyledStackedSidebar
      className={classNames({
        "t--app-viewer-navigation-sidebar": true,
        "is-open": isOpen,
        "shadow-xl": !isPinned,
      })}
      isMinimal={isMinimal}
      isSubMenuOpen={isSubMenuOpen}
      navColorStyle={navColorStyle}
      primaryColor={primaryColor}
      sidebarHeight={calculateSidebarHeight()}
    >
      <div className="flex relative" style={{ height: "inherit" }}>
        <div className="first-menu relative" style={{ height: "inherit" }}>
          {/* <NavigationLogo logoConfiguration={logoConfiguration} /> */}
          <ApplicationLogo
            appName={currentApplicationDetails?.name}
            navColorStyle={navColorStyle}
            navOrientation={NAVIGATION_SETTINGS.ORIENTATION.SIDE}
            navStyle={navStyle}
            primaryColor={primaryColor}
            showAbbr
          />
          <div className="menu-part">
            {filteredItems.map((firstChild: any) => {
              return (
                <div
                  className={`transition duration-100 ease-in-out text-center w-full menu-item hover:cursor-pointer ${
                    activeFirstMenu === firstChild.key
                      ? "first-active-menuitem"
                      : ""
                  }`}
                  key={firstChild.key}
                  onClick={() => onClickFirstMenu(firstChild)}
                  title={firstChild.title}
                >
                  {firstChild.icon ? <div>{firstChild.icon}</div> : null}
                  <div
                    className={`${
                      firstChild.icon
                        ? "menu-title menu-line1"
                        : "menu-title menu-line2"
                    }`}
                  >
                    {firstChild.title}
                  </div>
                </div>
              );
            })}
          </div>
          {/* 用户信息 */}
          {props.showUserSettings && (
            <StyledStackFooter
              navColorStyle={navColorStyle}
              primaryColor={primaryColor}
            >
              <SidebarProfileComponent
                currentUser={currentUser}
                isInline={false}
                isMinimal
                isOpen={isOpen}
                navColorStyle={navColorStyle}
                primaryColor={primaryColor}
              />
            </StyledStackFooter>
          )}
        </div>
        <div className="second-menu" style={{ height: "inherit" }}>
          <StyledStackedMenuContainer
            isStack
            navColorStyle={navColorStyle}
            primaryColor={primaryColor}
          >
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: primaryColor,
                },
                components: {
                  Menu: {
                    itemPaddingInline: 16,
                    darkItemSelectedColor: primaryColor,
                    itemBorderRadius: 6,
                    subMenuItemBorderRadius: 6,
                    horizontalItemBorderRadius: 6,
                  },
                },
              }}
            >
              <MyMenu
                className="rootSideMenu pp-menu"
                defaultSelectedKeys={initState.parentPaths}
                expandIcon={({ isOpen }) =>
                  isOpen ? (
                    <Icon name="arrow-down-s-line" size={"md"} />
                  ) : (
                    <Icon name="arrow-right-s-line" size={"md"} />
                  )
                }
                inlineCollapsed={!isOpen}
                items={restMenuData}
                mode="inline"
                navColorStyle={navColorStyle}
                onClick={pickMenu}
                onOpenChange={(e: any) => setOpenKeys(e)}
                openKeys={openkeys}
                primaryColor={primaryColor}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                }}
                theme={current_theme}
              />
            </ConfigProvider>
          </StyledStackedMenuContainer>
        </div>
        {!isMinimal && isSubMenuOpen && (
          <TheCollapsedIcon isOpen={isOpen}>
            <CollapseButton
              borderRadius={borderRadius}
              isOpen={isOpen}
              isPinned={isPinned}
              navColorStyle={navColorStyle}
              primaryColor={primaryColor}
              setIsPinned={setIsPinned}
            />
          </TheCollapsedIcon>
        )}
      </div>
    </StyledStackedSidebar>
  );
}

export default SidebarStacked;
